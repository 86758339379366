import React, { useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { isPathway } from "../../utils/is-pathway-to-net-zero";

import { Wrapper } from "../../Wrapper";
import styles from "../styles/TextWidget.module.scss";

export const TextWidget = ({ data, slug }: any) => {
  const ref = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (typeof window === "undefined") return;
    // Create a 'tiny' document and parse the html string
    const slotHtml = document.createRange().createContextualFragment(data.body);
    // Append it so it can be executed (allows <script> etc.)
    if (ref && ref.current) {
      ref.current.appendChild(slotHtml);
    }
  }, [ref, data.body]);

  const isPathwayPage = isPathway(slug);
  const pathwayStyle = isPathwayPage ? styles.pathway : null;
  // Optional background colour for the section from Wordpress

  return (
    <TextWrapper
      backgroundColor={data.background_colour}
      isPathway={isPathwayPage}
    >
      <Wrapper isLarge={isPathwayPage}>
        <section
          id="text-widget"
          className={`${styles.textWrapper} ${pathwayStyle}`}
          ref={ref}
        />
      </Wrapper>
    </TextWrapper>
  );
};

const TextWrapper = styled.div<{
  backgroundColor: string;
  isPathway: boolean;
}>`
  background-color: ${(props) => props.backgroundColor};
  ${(props) =>
    props.isPathway
      ? css`
          padding: 32px 0;
        `
      : css``}
`;
